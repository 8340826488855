const todosReducer = (state = [], action) => {
	switch (action.type) {
		default:
			return state;
	}
};

export default todosReducer;


